export default class UserAgent {
  private ua = '';
  public constructor(ua = navigator.userAgent) {
    this.ua = ua.toLowerCase();
  }
  public get isPc() {
    return !this.ua.match(/(iPhone|iPad|iPod|Android)/i);
  }

  public get isMSIE() {
    return this.ua.indexOf('msie') > -1 && this.ua.indexOf('opera') === -1;
  }
  public get isIE11() {
    return this.ua.indexOf('trident/7') > -1;
  }
  public get isIE() {
    return this.isMSIE || this.isIE11;
  }
  public get isEdge() {
    return this.ua.indexOf('edge') > -1;
  }
  public get isChrome() {
    return this.ua.indexOf('chrome') > -1 && this.ua.indexOf('edge') === -1;
  }
  public get isFirefox() {
    return this.ua.indexOf('firefox') > -1;
  }
  public get isSafari() {
    return this.ua.indexOf('safari') > -1 && this.ua.indexOf('chrome') === -1 && this.ua.indexOf('edge') === -1;
  }
  public get isOpera() {
    return this.ua.indexOf('opera') > -1;
  }
}
