import ObjectUtils from "@/utils/object-utils";
import {createMaxStringRule, requiredOnBlurRule, requiredRule} from "@/utils/validation-rules";
import {multiplyWithTax} from "@/services/tax";
import {eanCheckDigit} from "@/utils/barcode-util";
import UserAgent from "@/utils/user-agent";

export default class ProductLabelLayoutEntity {
  public id!:number;

  public layoutType:LayoutType = LayoutType.INLINE_DELIMITED_BY_BALL;
  public nutritionLayoutType:NutritionLayoutType = NutritionLayoutType.INLINE_BORDERLESS;
  public fontSize: number = 7.5;
  public expirationFormatType:number = 1;
  public showProductName:boolean = true;
  public showPrice:boolean = true;
  public priceFormatType:PriceFormatType = PriceFormatType.Both;
  public taxType:TaxType = TaxType.Ten;
  public roundupTax:boolean = false;

  public showJanCode:boolean = false;
  public janCode: string|null = null;
  public showQrCode:boolean = false;
  public qrCode:string|null = null;

  public get taxRatio(): number {
    return this.taxType / 100;
  }

  constructor(init:Partial<ProductLabelLayoutEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get fontSizeMayNotBeApplied(): boolean {
    return this.fontSize <= 7 && (new UserAgent).isChrome;
  }
}

export const ValidatorRules = {
  layoutType: [requiredRule],
  fontSize: [requiredRule],
  janCode: [
    {
      trigger: 'blur',
      validator: (rule, value, callback) => {
        if (!value) return callback();
        if (eanCheckDigit(value, true)) return callback();
        return callback(new Error('有効なJANコードを入力してください'));
      }
    },
  ],
  qrCode: [
    createMaxStringRule(512)
  ],
}

export enum LayoutType {
  INLINE_DELIMITED_BY_BALL = 1,
  BORDERLESS_TABLE = 2,
  BORDERED_TABLE = 3,
}
export const LayoutTypeDict = {
  [LayoutType.INLINE_DELIMITED_BY_BALL]: '一行、●区切り',
  // [LayoutType.BORDERLESS_TABLE]: '表形式、罫線なし',
  [LayoutType.BORDERED_TABLE]: '表形式、罫線あり',
}
export const LayoutTypeKeys = {
  [LayoutType.INLINE_DELIMITED_BY_BALL]: 'inline-delimited-by-ball',
  // [LayoutType.BORDERLESS_TABLE]: 'borderless-table',
  [LayoutType.BORDERED_TABLE]: 'bordered-table',
}
export enum NutritionLayoutType {
  INLINE_BORDERLESS = 1,
  TABLE_BORDERED = 2,
}
export const NutritionLayoutTypeDict = {
  [NutritionLayoutType.INLINE_BORDERLESS]: '行形式、罫線なし',
  [NutritionLayoutType.TABLE_BORDERED]: '表形式、罫線あり',
}
export const NutritionLayoutTypeKeys = {
  [NutritionLayoutType.INLINE_BORDERLESS]: 'InlineBorderless',
  [NutritionLayoutType.TABLE_BORDERED]: 'TableBordered',
}

export enum PriceFormatType {
  Both= 1,
  IncludeTax = 2,
  IncludeTaxWithLabel = 3,
  BothReverse = 4,
}
export enum TaxType {
  Eight = 8,
  Ten = 10,
}
export const PriceFormatTypeDict = {
  [PriceFormatType.Both]: '例）1,000円（税込 1,100円）',
  [PriceFormatType.IncludeTax]: '例）1,100円',
  [PriceFormatType.IncludeTaxWithLabel]: '例）1,100円（税込）',
  [PriceFormatType.BothReverse]: '例）1,100円（税抜価格 1,000円）',
}
export function formatPrice(price:number|null, layout:ProductLabelLayoutEntity): string {
  if(price === null) return '';
  const priceWithTax = layout.roundupTax ?
    Math.ceil(multiplyWithTax(price, layout.taxRatio)) :
    Math.floor(multiplyWithTax(price, layout.taxRatio));

  const format = {
    [PriceFormatType.Both]: '%p円（税込 %t円）',
    [PriceFormatType.IncludeTax]: '%t円',
    [PriceFormatType.IncludeTaxWithLabel]: '%t円（税込）',
    [PriceFormatType.BothReverse]: '%t円（税抜価格 %p円）',
  }[layout.priceFormatType];

  return format
    .replace("%t", priceWithTax.toLocaleString())
    .replace("%p", price.toLocaleString());
}
