import ObjectUtils from "@/utils/object-utils";
import {createMaxStringRule, requiredRule,} from "@/utils/validation-rules";


export default class ProductLabelIdentificationMarkEntity {
  public id!:number;

  public markType!:MarkType;
  public partsName:string|null = null;
  public materialNames:string[] = [];

  constructor(init:Partial<ProductLabelIdentificationMarkEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.materialNames){
      this.materialNames = init.materialNames;
    }
  }

  public get isMaterialNamesEnabled():boolean {
    return this.markType === MarkType.Plastic;
  }
  public get isEmpty(): boolean {
    return !this.markType && !this.partsName;
  }

}

export enum MarkType {
  Plastic = 1,
  Paper = 2,
  PET = 3,
  Steel = 4,
  Aluminium = 5,
}

export const MarkTypeDict = {
  [MarkType.Plastic]: 'プラマーク',
  [MarkType.Paper]: '紙マーク',
  [MarkType.PET]: 'PETマーク',
  [MarkType.Steel]: 'スチールマーク',
  [MarkType.Aluminium]: 'アルミマーク',
}

export const Materials = [
  "PBT",
  "PE",
  "PP",
  "ABS",
  "PMMA",
  "HDPE",
  "LDPE",
  "PVC",
  "PS",
  "PA",
  "POM",
  "SAN",
  "PET",
  "PC",
  "PF",
  "MF",
  "UF",
  "PLA",
  "PUR",
  "EP",
  "UP",
]

export const ProductLabelIdentificationMarkValidatorRules = {
  markType: [ requiredRule ],
  partsName: [ createMaxStringRule() ],
  materialNames: [ ],
};
