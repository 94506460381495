export function getCheckDigit(value:string) {
  const barcodeStr = ('00000' + value).slice(-13);

  let evenNum = 0, oddNum = 0;
  for (let i = 0; i < barcodeStr.length - 1; i++) {
    if (i % 2 == 0) { // 「奇数」かどうか（0から始まるため、iの偶数と奇数が逆）
      oddNum += parseInt(barcodeStr[i]);
    } else {
      evenNum += parseInt(barcodeStr[i]);
    }
  }
  const tripeEvenNum = evenNum * 3;
  const sum = tripeEvenNum + oddNum;

  const lastDigitOfSum = parseInt(sum.toString().slice(-1));
  if (lastDigitOfSum == 0) {
    return 0;
  }
  return (10 - lastDigitOfSum);
}

export function eanCheckDigit(value:string, allowWithoutCheckDigit:boolean) { // 引数は文字列
  const val = Number(value).toString();
  if ((val.length === 7 || val.length === 12) && allowWithoutCheckDigit) return true;
  if (val.length !== 8 && val.length !== 13) return false;

  const expectedCheckDigit = getCheckDigit(value);
  return expectedCheckDigit === parseInt(value.slice(-1));
}
