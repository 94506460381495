import ObjectUtils from "@/utils/object-utils";
import {
  AmountTypeEnum,
  ILabelAmount,
  ILabelEntity,
  LabelCategoryTypeEnum,
  LabelCategoryTypes,
  LabelExpirationTypeEnum,
  LabelExpirationTypes,
  LabelExtraFieldEnum,
  LabelExtraFields,
  MilkRatioUnitEnum,
  ResponsiblePattern,
} from "@/entities/interfaces/i-label-entity";
import {expirationDateFormatter} from "@/entities/specs/spec-entity";
import {
  FactoryType,
  PartnerEntity,
  PartnerType,
  PartnerTypeDict,
} from "@/entities/specs/partner-entity";
import SpecLabelCustomValueEntity, {
} from "@/entities/specs/spec-label-custom-value-entity";
import ProductLabelIdentificationMarkEntity, {
} from "@/entities/product-label-identification-mark-entity";

import addDays from 'date-fns/addDays';
import {NutritionValue} from "@/entities/nutrition-value";
import {getNutritionPerAmount} from "@/entities/specs/spec-nutrition-entity";
import cloneDeep from "lodash/cloneDeep";
import ProductEntity from "@/entities/product-entity";
import {PartnerBase} from "@/entities/specs/partner-entity-base";
import ProductDisplayServiceMadeInArea from "@/services/product-display-service-made-in-area";
import ILabelNutrition, { NutritionDisplayFormat } from "@/entities/interfaces/i-label-nutrition";
import {CompanySettingEntity} from "@/entities/company-setting-entity";

export const FACTORY_PATTERN_FACTORY_CODES = -1;
export type FactoryPattern = FactoryType | null | -1; // -1 is for factory pattern

export default class ProductLabelEntity implements ILabelEntity, ILabelAmount, ILabelNutrition {
  public id!:number;

  public categoryType:LabelCategoryTypeEnum|null = null;

  public amountType:AmountTypeEnum = AmountTypeEnum.Amount;
  public amount:string|null = null;
  public solidAmount:string|null = null;
  public totalAmount:string|null = null;

  public isMadeInAreaOverride:boolean = false;
  public madeInAreaOverrideText:string|null = null;
  public madeInAreaSubtext:string|null = null;

  public expirationType:LabelExpirationTypeEnum = LabelExpirationTypeEnum.BestBy;
  public isExpirationAuto:boolean = true;
  public expirationText:string|null = null;
  public expirationDaysBeforeOpen:number|null = null;

  public preservationMethod:string|null = null;

  public visibleExtraFieldKeys!:LabelExtraFieldEnum[];

  public precaution:string|null = null;
  public howToCook:string|null = null;
  public usage:string|null = null;
  public sanitizeMethod:string|null = null;
  public heatedBeforeFrozen:string|null = null;
  public needHeating:string|null = null;

  public starchRatio:number|null = null;
  public milkNonfatSolidRatio:number|null = null;
  public milkNonfatSolidRatioUnitType:MilkRatioUnitEnum = MilkRatioUnitEnum.Percent;
  public milkFatRatio:number|null = null;
  public milkFatRatioUnitType:MilkRatioUnitEnum = MilkRatioUnitEnum.Percent;
  public productLabelCustomValues: SpecLabelCustomValueEntity[] = [];

  public outsideText:string|null = null;

  public assortNutritionGrouped:boolean = true;
  public nutritionDisplayFormat: NutritionDisplayFormat = NutritionDisplayFormat.EstimatedPerGram;
  public nutritionUnit:string|null = null;
  public nutritionAmountPerUnit:number = 100;
  public nutritionIsGram:boolean = true;
  public nutritionGramPerMilliliter:number = 1;

  public responsibleCompanyType:PartnerType = PartnerType.Manufacturer;
  public responsibleCompanyId:number|null = null;
  public responsibleCompany:PartnerEntity|null = null;
  public responsibleFactoryId:number|null = null;
  public responsibleFactory:PartnerEntity|null = null;
  public responsibleFactoryUniqueCodes!:string[];
  public showFactoryName:boolean = true;

  public virtualResponsibleCompany!:PartnerBase;
  public readonly responsiblePattern!:ResponsiblePattern;

  public responsibleFactoryPattern:FactoryPattern = null;

  public showIdentificationMark:boolean = false;
  public identificationMarks:ProductLabelIdentificationMarkEntity[] = [];

  public telno:string|null = null;

  constructor(init:Partial<ProductLabelEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.productLabelCustomValues) {
      this.productLabelCustomValues = init.productLabelCustomValues.map(pf => new SpecLabelCustomValueEntity(pf));
    }
    this.visibleExtraFieldKeys = init.visibleExtraFieldKeys || [];

    if (init.responsibleCompany) {
      this.responsibleCompany = new PartnerEntity(init.responsibleCompany);
    }
    if (init.responsibleFactory) {
      this.responsibleFactory = new PartnerEntity(init.responsibleFactory);
    }
    if (init.virtualResponsibleCompany) {
      this.virtualResponsibleCompany = new PartnerBase(init.virtualResponsibleCompany);
    }

    this.responsibleFactoryUniqueCodes = init.responsibleFactoryUniqueCodes || [];

    if (this.responsibleFactory) {
      this.responsibleFactoryPattern = this.responsibleFactory.type as FactoryType;
    } else if (this.responsibleFactoryUniqueCodes.length > 0) {
      this.responsibleFactoryPattern = FACTORY_PATTERN_FACTORY_CODES;
    }

    if (init.identificationMarks) {
      this.identificationMarks = init.identificationMarks.map(m => new ProductLabelIdentificationMarkEntity(m));
    }
  }

  public getResponsibleCompanyTypeLabel(): string {
    return PartnerTypeDict[this.responsibleCompanyType];
  }

  public get categoryTypeLabel():string {
    if(!this.categoryType) return '';
    return LabelCategoryTypes[this.categoryType] || '';
  }
  public get expirationTypeLabel(): string {
    return LabelExpirationTypes[this.expirationType];
  }
  public getExpirationLabel(manufactureDate:Date, format:number):string {
    if (this.isExpirationAuto) {
      const exp = this.getExpirationDate(manufactureDate);
      if(!exp) return '';

      return expirationDateFormatter(exp, format);
    } else {
      return this.expirationText || '';
    }
  }
  public getExpirationDate(manufactureDate:Date): Date|null {
    if (this.expirationDaysBeforeOpen === null) return null;
    return addDays(manufactureDate, this.expirationDaysBeforeOpen - 1); // 製造日を含むので-1する
  }

  public getNutritionPerAmount(valuePer100g: NutritionValue, property: string): NutritionValue {
    return getNutritionPerAmount(
      valuePer100g, this.nutritionAmountPerUnit, this.nutritionIsGram, this.nutritionGramPerMilliliter, property
    );
  }
  public get shouldPrintFactory() :boolean {
    return !!this.responsibleFactory && (this.responsibleFactoryPattern || 0) > 0;
  }
  public get responsibleFactoryUniqueCodesLabel():string {
    return this.responsibleFactoryUniqueCodes.filter(u => !!u).map(c => `+${c}`).join(' ');
  }


  public isMadeInAreaSubTextVisible(product:ProductEntity, companySetting: CompanySettingEntity): boolean {
    if (this.isMadeInAreaOverride) return true;
    if (!product.productDisplaySetting.isMadeInAreasRatioVariable) return false;
    const areas = (new ProductDisplayServiceMadeInArea(product, product.productDisplaySetting, companySetting))
      .getTargetMadeInAreasUniqStatic();
    return areas.length > 1;
  }
  public getOutsideNote(product:ProductEntity, companySetting: CompanySettingEntity, separator = "\n"): string {
    if (this.isMadeInAreaSubTextVisible(product, companySetting)) {
      return [
        this.madeInAreaSubtext || null,
        this.outsideText || null
      ].filter(s => s !== null).join(separator);
    } else {
      return this.outsideText || '';
    }
  }

  public serialize(): ProductLabelEntity {
    const data = cloneDeep(this) as ProductLabelEntity;
    if (this.visibleExtraFieldKeys.includes(LabelExtraFieldEnum.CustomValues)) {
      data.productLabelCustomValues = data.productLabelCustomValues.filter(d => !d.isEmpty);
    } else {
      data.productLabelCustomValues = [];
    }

    if(data.showIdentificationMark) {
      data.identificationMarks = data.identificationMarks.filter(im => !im.isEmpty);
    } else {
      data.identificationMarks = [];
    }

    return data;
  }
}

export {LabelExtraFieldEnum};
export {LabelExtraFields};
