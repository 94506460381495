import RepositoryBase, {
  extractFileNameFromResponseHeader,
  IFindOption,
  saveDownloadedBlob
} from "@/repositories/repository-base";
import ProductEntity from "@/entities/product-entity";
import AttachmentEntity from "@/entities/attachment-entity";
import ObjectUtils from "@/utils/object-utils";
import {IPaginator} from "@/contracts/i-paginator";
import CustomCache from "@/utils/custom-cache";
import AuditEntity from "@/entities/audit-entity";
import FileSaver from "file-saver";
import {AxiosResponse} from "axios/index";
import ProductDisplayService, {IMergedNormalizedItem, INormalizedItem} from "@/services/product-display-service";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import {IResourceBelongingToDepartment} from "@/services/company-department-service";
import CompanyEntity from "@/entities/company-entity";
import ProductDisplayServiceAdditive, {
  IMergedNormalizedAdditiveItem
} from "@/services/product-display-service-additive";

export default class ProductRepository extends RepositoryBase<ProductEntity> {
  protected ctor:new(data) => ProductEntity = ProductEntity;
  protected static cache:CustomCache<any[]> = new CustomCache();

  public constructor(companyId:string|number) {
    super();
    this.endpoint = `companies/${companyId}/products`
  }

  public async list(excludeId:number|undefined = undefined): Promise<ProductListType[]>  {
    const res = await this.get(this.endpoint + '/list');
    return res.data.filter(d => d.id !== excludeId);
  }

  public async search(query, params): Promise<IPaginator<ProductForIndex>>  {
    const p = Object.assign({_as_pagination: true, q:query}, params);
    const res = await this.get(this.endpoint + "/search", p);
    return this.toPagination(res, ProductForIndex);
  }

  public async getAttachments(id:number): Promise<AttachmentEntity[]> {
    const res = await this.get(this.endpoint + `/${id}/attachments`);
    return res.data.map(a => new AttachmentEntity(a));
  }

  public async getAuditLogs(id:number): Promise<AuditEntity[]> {
    const res = await this.get(this.endpoint + `/${id}/audits`);
    return res.data.map(a => new AuditEntity(a));
  }

  public async count():Promise<number> {
    const res = await this.get(`${this.endpoint}/count`);
    return Number(res.data);
  }

  public async downloadDepartmentReport(product: ProductEntity, company:CompanyEntity): Promise<AxiosResponse> {
    const service = new ProductDisplayService(product, product.productDisplaySetting, company.setting);
    return super.get(this.endpoint + `/${product.id}/reports/department`, {
      area: service.getMadeInNames(false),
    }, {
      responseType: 'blob'
    });
  }

  public async downloadLabelOrderEvidence(products:ProductEntity[], company:CompanyEntity): Promise<void> {
    console.debug('products', products);

    const data = products.map(product => {
      const items: INormalizedItem[] = (product.isAssort || product.isProductMix) ?
        ProductDisplayService.normalizeForProductMix(product, product.productDisplaySetting) :
        ProductDisplayService.normalize(product, product.productDisplaySetting);
      const service = new ProductDisplayService(product, product.productDisplaySetting, company.setting);
      const additives: IMergedNormalizedAdditiveItem[] = ProductDisplayService.getMergedAdditives(product, product.productDisplaySetting);
      const ingredients: IMergedNormalizedItem[] = service.mergeAndSortNormalizedItem(product, items);

      const trimIngredients = (ings: IMergedNormalizedItem[]) => {
        // ingredientsがあるとpost時エラーになる
        ings.forEach(i => {
          i.ingredients = [];
          trimIngredients(i.items);
        })
        return ings;
      };
      const ingredients2 = trimIngredients(ingredients);
      const ingredients3 = ProductDisplayService.excludeFromIngredientNamesRecursive(ingredients2, product.productDisplaySetting.isWaterOmitted, true);

      return {
        product: {
          id: product.id,
          name: product.name,
          intraCode: product.intraCode,
          weight: product.getAmountSumGram(),
        },
        ingredientName: service.getIngredientNames(),
        allergens: service.getAllergenNamesByItems(items),
        ingredients: ingredients3,
        additives: additives,
      }
    });

    console.debug('data', data);

    const res = await super.post(`${this.endpoint}/label-order-evidence`, {
      rows: data
    }, {
      responseType: 'blob'
    });

    const fileName = extractFileNameFromResponseHeader(res);
    saveDownloadedBlob(res, fileName);
  }

  public async downloadNutritionEvidenceSimple(idList:number[]): Promise<void> {
    const res = await this.get(this.endpoint + `/nutrition-evidence-simple`, idList, {
      responseType: 'blob',
    });
    const fileName = extractFileNameFromResponseHeader(res);
    saveDownloadedBlob(res, fileName);
  }
  public async downloadNutritionEvidenceDetail(idList:number[]): Promise<void> {
    const res = await this.get(this.endpoint + `/nutrition-evidence-detail`, idList, {
      responseType: 'blob',
    });
    const fileName = extractFileNameFromResponseHeader(res);
    saveDownloadedBlob(res, fileName);
  }
  public async downloadAllergenList(idList:number[]): Promise<void> {
    const res = await this.get(this.endpoint + `/allergen-list`, idList, {
      responseType: 'blob',
    });
    const fileName = extractFileNameFromResponseHeader(res);
    saveDownloadedBlob(res, fileName);
  }

  public async lock(id:number): Promise<void> {
    await this.put(`${this.endpoint}/${id}/lock`);
  }
  public async unlock(id:number): Promise<void> {
    await this.put(`${this.endpoint}/${id}/unlock`);
  }
}

export class ProductForIndex implements IResourceBelongingToDepartment {
  public id!:number;
  public name!:string;
  public intraCode!:string|null;
  public displayName!:string;
  public price!:number;
  public tags!:string[];
  public updatedAt!:string;
  public referredSpecsCount!: number;
  public departments!: CompanyDepartmentEntity[];

  constructor(init:ProductForIndex) {
    ObjectUtils.assignLiteralFields(this, init);
    this.tags = init.tags || [];
    this.departments = init.departments || [];
  }

  public getDepartmentIds(): number[] {
    return this.departments.map(d => d.id);
  }
}

export type ProductListType = {
  id:number;
  companyOrd:number;
  name:string;
  yieldPercent: number | null;
  isYieldAffectToNutrition: boolean;
  showLabelSetting: boolean;
  intraCode: string | null;
  tags: string[];
};


