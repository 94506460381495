import ObjectUtils from "@/utils/object-utils";

export default class ProductCommonNameEntity {
  public id!:number;
  public name!:string;
  public searchKey:string = '';
  public rootCategoryName?:string;

  constructor(init:Partial<ProductCommonNameEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get displayName():string {
    return this.rootCategoryName ? `${this.name}（${this.rootCategoryName}）` : this.name;}
}
